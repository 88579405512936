import React from "react"
import Root from "./GoogleMaps.style"

const GoogleMaps = () => {
  return (
    <Root.Wrapper>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1690.4231648308646!2d34.797322343729014!3d32.073404839653215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4ba2781b990f%3A0x7404db9ff4a40330!2z16rXldem16jXqiDXlNeQ16jXpSA2LCDXqtecINeQ15HXmdeRINeZ16TXlQ!5e0!3m2!1siw!2sil!4v1598952276701!5m2!1siw!2sil"
        width="600"
        height="450"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        title="map"
      ></iframe>
    </Root.Wrapper>
  )
}

export default GoogleMaps
