import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import LeadForm from "../components/LeadForm"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"
import styled from "styled-components"
import Head from "../components/Head"
import GoogleMaps from "../components/GoogleMaps"
import bgImgHeader from "../../assets/images/pic11.jpg"

const E = {
  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .text {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      @media (max-width: ${breakpoints.medium}px) {
        max-width: 90%;
        flex-basis: 90%;
        padding: 0 5px;
        padding-bottom: 20px;
      }

      h3 {
        font-weight: bold;
        margin: 20px 0 20px 0;
      }

      p {
        font-weight: 300;
        padding-left: 30px;
      }
    }

    .form {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;

      @media (max-width: ${breakpoints.medium}px) {
        max-width: 90%;
        flex-basis: 90%;
        padding: 0 5px;
      }
    }
  `,

  Section: styled.section`
    margin: 100px 0;
  `,
}

const ContactPage = () => (
  <Layout>
    <Seo title="צור קשר" />

    <Head title="צור קשר" img={bgImgHeader} />

    <E.Section>
      <div className="container">
        <E.Row>
          <div className="text">
            <h3>שמור על קשר</h3>
            <p>
              נשמח לשוחח איתך על הדרך שבה נוכל לעזור לך למטב את תיק הנכסים
              הפנסיונים והפיננסים שלך
            </p>
          </div>
          <div className="form">
            <LeadForm />
          </div>
        </E.Row>
        <E.Section>
          <GoogleMaps />
        </E.Section>
      </div>
    </E.Section>
  </Layout>
)

export default ContactPage
