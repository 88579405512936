import styled from "styled-components"

const Root = {
  Wrapper: styled.div`
    iframe {
      border: 0;
      width: 100%;
      filter: grayscale(50%);
  `,
}

export default Root
